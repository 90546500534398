import React from "react";

// eslint-disable-next-line
export const Tour = () => {
  return (
    <></>
  );
};

export default Tour;
